import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"mt-6 grey-light pa-8"},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"md":"7","lg":"8"}},[_c('h1',{staticClass:"pb-2"},[_vm._v("You’re off to the races…")]),_c('p',[_vm._v(" Congratulations! One of our Amplifyers is going to produce a news article, blog post, video, slideshow and audio for your campaign, which you can then publish to hundreds of sites at a push of a button. ")]),_c('p',[_vm._v(" IMPORTANT: Our Amp writers are experts in getting content approved which also gets seen, so we need to follow certain rules and styles to get published and get you results. Please read more about this "),_c('a',{attrs:{"href":"https://ampifire.com/training/ampifire/faqs#pr_30","target":"_blank"}},[_vm._v(" here ")]),_vm._v(" . ")]),_c(VBtn,{staticClass:"mt-8",attrs:{"x-large":"","color":"primary","to":"/announcements"}},[_vm._v(" Visit My Home Page ")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c(VImg,{attrs:{"src":require('@/assets/img/success.png')}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }