<template>
    <v-container class="mt-6 grey-light pa-8">
        <v-row>
            <v-col md="7" lg="8" class="pb-0">
                <h1 class="pb-2">You’re off to the races…</h1>

                <p>
                    Congratulations! One of our Amplifyers is going to produce a
                    news article, blog post, video, slideshow and audio for your
                    campaign, which you can then publish to hundreds of sites at
                    a push of a button.
                </p>

                <p>
                    IMPORTANT: Our Amp writers are experts in getting content
                    approved which also gets seen, so we need to follow certain
                    rules and styles to get published and get you results.
                    Please read more about this
                    <a
                        href="https://ampifire.com/training/ampifire/faqs#pr_30"
                        target="_blank"
                    >
                        here
                    </a>
                    .
                </p>

                <v-btn x-large color="primary" to="/announcements" class="mt-8">
                    Visit My Home Page
                </v-btn>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5" lg="4">
                <v-img :src="require('@/assets/img/success.png')" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Success extends Vue {}
</script>
